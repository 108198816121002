// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karriar-tsx": () => import("./../src/pages/karriar.tsx" /* webpackChunkName: "component---src-pages-karriar-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-om-oss-tsx": () => import("./../src/pages/om-oss.tsx" /* webpackChunkName: "component---src-pages-om-oss-tsx" */),
  "component---src-templates-job-tsx": () => import("./../src/templates/job.tsx" /* webpackChunkName: "component---src-templates-job-tsx" */)
}

