/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/assets/global.less';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPaperPlane,
  faMoneyBillWaveAlt,
  faBook,
  faBalanceScale,
  faUserNinja,
  faEnvelopeOpenText,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { MediaContextProvider } from './src/utils/media';

library.add(
  fab,
  faTimes,
  faPaperPlane,
  faMoneyBillWaveAlt,
  faBook,
  faBalanceScale,
  faUserNinja,
  faEnvelopeOpenText,
);
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <MediaContextProvider>
    { element }
  </MediaContextProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  // const scrollToTopRoutes = ['/privacy-policy', '/page-2'];
  // // if the new route is part of the list above, scroll to top (0, 0)
  // if (scrollToTopRoutes.indexOf(pathname) !== -1) {
  // }

  const el = document.getElementById('gatsby-focus-wrapper');
  if (!el) {
    return null;
  }
  el.scrollTo(0, 0);
  return false;
};
