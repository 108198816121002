
export default {
  useCustomProperties: false,
  colors: {
    text: '#21366E',
    background: '#fff',
    primary: '#21366E',
    secondary: '#6DB6E1',
    lightBlue: '#C8DFF1',
    lightGreen: '#89e29c',
    warning: '#F7C851',
    danger: '#E4002B',
    yellow: '#F7C851',
    muted: '#BCC3D4',
    white: '#FFF',
    roundedBorder: '#f3f3f3',
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 256, 512],
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64, 96],
  fonts: {
    body: 'Roboto',
    heading: 'Crete Round',
  },
  buttons: {
    secondary: {
      cursor: 'pointer',
      transition: 'ease-out 200ms all',
      '&:not(:disabled):hover': {
        opacity: 0.95,
      },
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    primary: {
      transition: 'ease-out 200ms all',
      fontFamily: 'heading',
      alignItems: 'center',
      fontWeight: 'body',
      px: 4,
      py: [3, 3, 3],
      boxShadow: '0px 15px 30px -14px rgba(0,0,0,0.7)',
      cursor: 'pointer',
      opacity: 1,
      '&:not(:disabled):hover': {
        opacity: 0.95,
        transition: 'ease-out 100ms all',
        boxShadow: '0px 20px 35px -10px rgba(0,0,0,0.4)',
      },
      '&:active': {
        opacity: 1,
        boxShadow: '0px 20px 35px -10px rgba(0,0,0,0.2)',
      },
      '&:focus': {
        outline: 'none',
      },
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.53,
    heading: 1.53,
  },
  letterSpacings: {
    heading: ['-2px', '-2px', 0],
  },
  breakpoints: [
    '321px',
    '576px',
    '768px',
    '992px',
    '1200px',
  ],
  radii: {
    default: '25px',
  },
  shadows: {
    frameShadow: '0px 20px 70px -24px rgba(0,0,0,0.3)',
    lightFrameShadow: '0px 29px 80px -35px rgba(0,0,0,0.3)',
    burgerMenuShadow: '0px 0px 70px -24px rgba(0,0,0,0.3)',
  },
  text: {
    heading: {
      letterSpacing: 'heading',
      wordWrap: 'break-word',
      fontWeight: 'body',
    },
    body: {
      lineHeight: 'body',
    },
  },
  forms: {
    label: {
      color: 'primary',
      fontSize: 2,
    },
    textarea: {
      appearance: 'none',
      borderColor: 'muted',
      ':focus': {
        outline: 'none',
        boxShadow: '0px 0px 4px #6DB6E1',
      },
    },
    input: {
      appearance: 'none',
      borderColor: 'muted',
      ':focus': {
        outline: 'none',
        boxShadow: '0px 0px 4px #6DB6E1',
      },
    },
  },
  layout: {
    container: {
      px: [
        3,
        4,
        4,
        0,
        0,
        0,
      ],
      maxWidth: [
        'none',
        '516px',
        '700px',
        '700px',
        '850px',
        '1020px',
      ],
    },
  },
  links: {
    nav: {
      fontFamily: 'heading',
      color: 'text',
      '&.active': {
        color: 'secondary',
      },
    },
  },
  styles: {
    h1: {
      letterSpacing: ['-2px', '-2px', 0],
      fontWeight: 'body',
      wordWrap: 'break-word',
      lineHeight: 'heading',
      mb: 4,
      '&.title': {
        mb: 0,
        fontSize: '3rem',
      },
    },
    footer: {
      '.footer-meta a': {
        color: ['lightBlue', 'lightBlue', 'muted'],
      },
    },
    root: {
      '#gatsby-focus-wrapper': {
        position: 'relative',
        overflowX: 'hidden',
      },
      '.error-message': {
        color: 'danger',
        fontSize: 1,
      },
      '.has-error': {
        borderColor: 'danger',
        ':focus': {
          borderColor: 'none',
          outline: 'none',
          boxShadow: '0px 0px 4px #E4002B',
        },
      },
      h2: {
        lineHeight: 'heading',
        mb: 4,
      },
      h3: {
        lineHeight: 'heading',
      },
      h4: {
        lineHeight: 'heading',
      },
      h5: {
        lineHeight: 'heading',
      },
      h6: {
        fontFamily: 'body',
        fontSize: 3,
        lineHeight: 'heading',
      },
      main: {
        position: 'relative',
        overflow: 'hidden',
      },
      blockquote: {
        my: 5,
      },

      /* ----------- */
      /*   General   */
      /* ----------- */
      p: {
        lineHeight: 'body',
      },
      'h2,h3,h4,h5': {
        fontFamily: 'body',
        '&.highlight': {
          color: 'secondary',
        },
      },
      a: {
        color: 'text',
        transition: 'ease-in-out 100ms all',
        textDecoration: 'none',
        '&:hover': {
          opacity: [1, 1, 0.7],
        },
        '&:active': {
          opacity: 0.7,
        },
        '&:focus': {
          outline: 'none',
        },
      },
      /* ---------------------- */
      /* Capabilities component */
      /* ---------------------- */
      '.capability-container': {
        width: '100%',
        overflow: 'visible',
        div: {
          overflow: 'visible',
        },
        foreignObject: {
          overflow: 'visible',
        },
        '#head,#butt': {
          stroke: 'primary',
        },
        svg: {
          overflow: 'visible',
          zIndex: 1,
          circle: {
            stroke: 'primary',
            strokeWidth: 1,
            fill: 'white',
            filter: 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))',
          },
        },
        '.cap-line': {
          overflow: 'visible',
          stroke: 'primary',
        },
        '.svg-inline--fa': {
          width: '100%',
        },
        p: {
          fill: 'text',
          fontFamily: 'heading',
          fontWeight: 'body',
          fontSize: 2,
          textAlign: 'center',
          display: 'inline-block',
          zIndex: 1,
        },
      },
      /* ----------- */
      /* Burger menu */
      /* ----------- */
      '.bm-menu-wrap': {
        '&.open': {
          boxShadow: '0px 0px 70px -24px rgba(0,0,0,0.3)',
        },
        '.bm-menu': {
          backgroundColor: 'white',
          pt: 6,
          px: 4,
          '.bm-item-list > a': {
            color: 'text',
            pt: 3,
            textAlign: 'center',
            '&.active': {
              color: 'secondary',
            },
          },
        },
      },
      '.burger-menu-wrapper': {
        position: 'absolute',
        top: 0,
        right: 0,
        pr: 4,
        pt: 4,
        zIndex: 9999,
        appearance: 'none',
        background: 'none',
        cursor: 'pointer',
        border: 'none',
        '&:focus': {
          outline: 'none',
        },
        '.burger-menu': {
          width: ['20px', '25px'],
          height: ['3px', '4px'],
          mt: 2,
          mb: 2,
          position: 'relative',
          color: 'text',
          '&:before,&:after,&': {
            backgroundColor: 'text',
          },
          '&.open': {
            '&:before,&:after,&': {
              backgroundColor: 'text',
            },
          },
        },
      },
    },
  },
};
