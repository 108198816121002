import { createMedia } from '@artsy/fresnel';

export const AppMedia = createMedia({
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1700,
  },
});

export const { MediaContextProvider, Media } = AppMedia;

export const mediaStyles = AppMedia.createMediaStyle();
